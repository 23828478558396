import {ProductListCommon} from './module/product-list/product-list-common';

// product list
const productListCommon = new ProductListCommon();
productListCommon.initClickableProducts();
productListCommon.addClickBehaviour(
    document.querySelectorAll('.js-hp-nav__list-item'),
    '.js-hp-nav__list-item-link',
    '_self'
);
